<template>
  <div class="container">
    <van-tabs v-model="tabActive" class="mb10" @click="onTab">
      <van-tab v-for="(item,index) in tabs" :key="index" :title="item.name" />
    </van-tabs>
    <div class="main">
      <van-list v-model="listLoading" class="list" :finished="listFinished" :finished-text="listFinishedText" @load="onListLoad">
        <div v-for="item in list" :key="item.meeting_id" class="list-item" @click="onNavigator({path:'/events/detail?meeting_id=' + item.meeting_id + '&appid=' + appid})">
          <div class="list-item-media">
            <van-image :src="item.meeting_cover" class="list-item-media-img" />
            <div class="list-item-price">
              {{ item.meeting_price }}
            </div>
          </div>
          <div class="list-item-hd">
            <div class="list-item-title">
              {{ item.meeting_name }}
            </div>
          </div>
          <div class="list-item-bd">
            <div class="list-item-txt">
              {{ item.meeting_address }}
            </div>
            <div class="list-item-txt">
              {{ item.meeting_start_date }} - {{ item.meeting_end_date }}
            </div>
            <div class="list-item-count">
              {{ item.already_count }} / {{ item.ticket_count }}
            </div>
          </div>
        </div>
      </van-list>
    </div>
  </div>
</template>
<script>

import PageMixin from '@/mixins/page'
import { Tab, Tabs } from 'vant'

export default {
  name: 'Events',
  components: {
    [Tab.name]: Tab,
    [Tabs.name]: Tabs
  },
  mixins: [PageMixin],
  data() {
    return {
      tabs: [
        { name: '进行中', api: '1' },
        { name: '我的活动', api: 'profile_events' }
      ],
      tabActive: 0,
      list: [],
      listLoading: false,
      listFinished: false,
      listFinishedText: '没有更多了',
      pageSize: 10,
      pageCurrent: 1,
      appid:localStorage.getItem('appid')
    }
  },
  created() {

  },
  methods: {
    onTab(index) {
      if (index === 0) {
        this.pageCurrent = 1
        this.onPage()
      } else {
        this.$router.push({
          path: '/events/mine'
        })
      }
    },
    onListLoad() {
      this.onPage()
    },
    onPage() {
      this.$api.events_index({ 'per-page': this.pageSize, page: this.pageCurrent })
        .then(res => {
          this.listLoading = false
          this.list = Number(res._meta.currentPage) === 1 ? res.items : this.list.concat(res.items)
          this.listFinished = this.list.length >= res._meta.totalCount
          this.listFinishedText = this.list.length ? '没有更多了' : '暂无活动~'
          this.pageCurrent += 1
        })
    }
  }
}
</script>

<style lang="less" scoped>

  .list{

  }

  .list-item{
    padding: 10px 15px;
    background: #fff;
    margin-bottom: 10px;
    &-media{
      position: relative;
      &-img{
        width: 346px;
        height: 180px;
      }
    }
    &-title{
      font-size: 16px;
      font-weight: bold;
      color: #333;
      margin-bottom: 6px;
    }
    &-txt{
      font-size: 12px;
      color: #666;
    }
    &-bd{
      position: relative;
    }
    &-count{
      position: absolute;
      bottom: 0;
      right: 0;
      font-size: 14px;
      color: #999;
    }
    &-price{
      position: absolute;
      top: 30px;
      right: -8px;
      background: #ff993e;
      color: #fff;
      padding: 6px 10px;
      font-size: 12px;
      border-radius: 4px;
    }
  }
</style>

